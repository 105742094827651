html {
  height: 100%;
}

body {
  height: 100%;
  font-family: "DM Sans", sans-serif;
}

/* TODO REMOVE CONDITIONAL FIX FOR "RESIZE OBSERVER ERROR OVERLAY" */
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

/* Medications Modal */

.medications-modal.ant-modal {
  padding: 0px;
  width: 800px !important;
}

.subscriptions-modal.ant-modal,
.leads-modal.ant-modal {
  width: 90% !important;
  height: 90% !important;
  max-width: 1000px;
  max-height: 750px;
  overflow: hidden;
}

.medications-modal.main-modal.ant-modal .ant-modal-header {
  padding: 22px 30px 22px;
}

.medications-select-option.ant-select-item {
  padding: 0px;
}

.medications-select-option .ant-select-item-option-content {
  white-space: break-spaces;
  padding: 5px 13px;
  border-bottom: 1px solid #f2f2f2;
  text-align: left;
  font: normal normal normal;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0px;
  opacity: 1;
}

/* Investigations Modal */

.investigations-modal.ant-modal {
  padding: 0px;
  width: 800px !important;
}

.investigations-modal.main-modal.ant-modal .ant-modal-header {
  padding: 22px 30px 22px;
}

/* Examinations Modal */

.examination-modal.ant-modal {
  padding: 0px;
  width: 676px !important;
}

/* General modal */

.main-modal.ant-modal .ant-modal-content {
  border-radius: 10px;
  border: 1px solid #707070;
}

.main-modal.ant-modal .ant-modal-header {
  border-radius: 10px 10px 0 0;
  padding: 22px 51px 22px;
  border-bottom: 1px solid #002e4640;
}

.main-modal.ant-modal .ant-modal-title {
  text-align: left;
  font: normal normal medium 18px/22px DM Sans;
  font-weight: 400;
  letter-spacing: 0px;
  color: #002e46;
  opacity: 0.75;
}

.main-modal .ant-modal-body {
  padding: 0px !important;
  max-height: calc(100vh - 100px);
}

.main-modal.ant-modal .ant-modal-footer {
  padding: 22px 51px 22px;
  border-radius: 0px 0px 10px 10px;
  border-top: 1px solid #002e4640;
}

.main-modal.ant-modal .ant-modal-footer .ant-btn {
  padding: 10px 20px;
  height: auto;
  border-radius: 6px;
}

/* Remove Broswer Autofill background */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

/* Popover Styles */
.password-hints-popover.ant-popover .ant-popover-content .ant-popover-inner {
  background-color: #e0eefb;
  box-shadow: 0px 1px 3px #002e4640;
  border-radius: 5px;
  text-align: left;
  font: normal normal normal 13px/13px DM Sans;
  letter-spacing: 0px;
  color: #002e46;
}

.password-hints-popover.ant-popover .ant-popover-content .ant-popover-arrow {
  border-right-color: #e0eefb;
  border-bottom-color: #e0eefb;
}

.ant-popover-inner-content {
  padding-bottom: 2px;
  padding: 8px 0 8px;
}

.ant-popover-content p {
  padding: 6px 18px;
  margin: 0 !important;
  user-select: none;
  transition: all 0.25s ease;
}

.ant-popover-content p:hover {
  background-color: #0f9af01a;
}

.password-hints-popover.ant-popover
  .ant-popover-content
  .ant-popover-inner
  p.title {
  font-weight: bold;
}

/* Chat More menu */
.more-menu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  font: normal normal medium 13px/9px DM Sans;
  letter-spacing: 0px;
  color: #002e46d9;
  opacity: 1;
}

.more-menu .ant-dropdown-menu-item.end-chat {
  color: #ff2366;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 40px !important;
}

.sidebar-drawer .ant-drawer-body {
  padding: 0px !important;
}

.sidebar-drawer .ant-drawer-body aside {
  position: relative !important;
  display: block;
  box-shadow: none;
  margin-top: 0px;
  height: auto;
}

.sidebar-drawer .ant-drawer-body aside ul {
  padding: 0px 20px;
}

@media (max-width: 450px) {
  .form-drawer .ant-drawer-content-wrapper,
  .lab-request-drawer .ant-drawer-content-wrapper,
  .leads-drawer .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.dashboard-mode-modal .ant-modal-content {
  border-radius: 6px;
}

.dashboard-mode-modal .ant-modal-header {
  border-radius: 6px 6px 0 0;
}

.dashboard-mode-modal .ant-modal-footer {
  padding: 20px 25px;
}

/* Swal */
.swal2-title.payment_modal__title {
  padding-top: 2px;
}

.swal2-icon.payment_modal__icon {
  border: none;
}

div.swal2-icon-content img {
  height: 65px;
}

/* OTP input */
.otp-input {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 25px;
  justify-content: center;
}

.otp-input input {
  height: 60px;
  width: 50px !important;
  border: 2px solid rgb(223 218 218);
  border-radius: 6px;
  margin-right: 10px;
  font-size: 16px;
  text-transform: uppercase;
}

@media (max-width: 541px) {
  .otp-input input {
    height: 50px;
    width: 40px !important;
    margin-right: 5px;
    font-size: 12px;
  }
}

@media (max-width: 376px) {
  .otp-input input {
    height: 40px;
    width: 30px !important;
    margin-right: 5px;
    font-size: 12px;
  }
}

/* react phone input */
.phone-input {
  width: 100% !important;
  height: 45px !important;
}

/* DOB Input Firefox fix */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Gallery modal */
.gallery-modal {
  width: 85% !important;
  max-width: 1000px !important;
}

.gallery-modal .ant-modal-body {
  height: 90%;
  max-height: 533px;
  overflow: hidden;
}

.gallery-modal .ant-modal-body h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  color: #344054;
  margin-bottom: 4px;
}

.gallery-modal .ant-modal-body p {
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #667085;
  margin: 0;
}

.gallery-modal .ant-modal-body p.loading {
  text-transform: capitalize;
  margin-top: 32px;
  font-size: 18px;
}

.gallery-modal .ant-modal-body .gallery-grid {
  margin-top: 32px;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  max-height: 400px;
  overflow-y: scroll;
}

.gallery-modal .ant-modal-body .gallery-grid .single-image-wrapper {
  height: 150px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.gallery-modal .ant-modal-body .gallery-grid .single-image-wrapper span {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 13px;
  padding: 10px;
  color: #767676;
}

.gallery-modal .ant-modal-body .gallery-grid .single-image-wrapper span a {
  color: #767676;
  text-decoration: underline;
}

.gallery-modal .ant-modal-body .gallery-grid img {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  opacity: 0.85;
  transition: all 0.3s ease;
}

.gallery-modal .ant-modal-body .gallery-grid img:hover {
  opacity: 1;
}
